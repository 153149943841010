// src/App.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { countryCodes } from './countryCodes';
import { useNavigate } from 'react-router-dom';
import SuccessPage from "./SuccessPage"


// Stripe public key
// const stripePublicKey = "pk_live_51P5lNvIoboGXb9jKMmfDawsa2VtGaiyk7L0wC8Qe0DqDSAh9acr9sOMsclbqFF9he32fe8OAvHM8832yTrBmfndP00a6YibdJq";
const stripePublicKey = "pk_live_51P5lNvIoboGXb9jKMmfDawsa2VtGaiyk7L0wC8Qe0DqDSAh9acr9sOMsclbqFF9he32fe8OAvHM8832yTrBmfndP00a6YibdJq"

const stripePromise = loadStripe(stripePublicKey);

const API_URL = process.env.REACT_APP_API_URL || 'api';

const App = () => {
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [userDetails, setUserDetails] = useState({
        name: '',
        email: '',
        phone: '',
        tradingViewId: '',
        country: '',
        state: '',
        postalCode: '',
        address: ''
    });
    const [countryCode, setCountryCode] = useState(countryCodes[0].code);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${API_URL}/algomaxx/plans?for=algomaxx`); // Fetch plans from the backend
                console.log('Plans response:', response.data); // Log the response

                if (Array.isArray(response.data)) {
                    setPlans(response.data);
                    if (response.data.length > 0) {
                        setSelectedPlan(response.data[0]);
                    }
                } else {
                    toast.error('Invalid response format. Expected an array.');
                }
            } catch (error) {
                toast.error('Error fetching plans. Please try again later.');
            }
        };

        fetchPlans();
    }, []);

    const handleChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!userDetails.email || !userDetails.phone || !userDetails.tradingViewId) {
            toast.error('Email, phone number, and TradingView ID are required.');
            return;
        }

        if (userDetails.phone.length !== 10) {
            toast.error('Phone number must be exactly 10 digits.');
            return;
        }

        userDetails.phone = userDetails.country + userDetails.phone;

        if (!termsAccepted) {
            toast.error('You must accept the terms and conditions.');
            return;
        }

        console.log('Form submitted with user details:', userDetails);
    };

    useEffect(() => {
        if (paymentSuccess) {
            navigate('/success');
        }
    }, [paymentSuccess, navigate]);

    return (
        <Elements stripe={stripePromise}>
            <div className="payment-page">
                <div className="payment-container">
                    <ToastContainer />
                    <form className="user-form" onSubmit={handleSubmit}>
                        <div className="customer-info">
                            <h3>Customer Information</h3>

                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    className="form-input"
                                    placeholder="Full Name"
                                    value={userDetails.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group phone-input-group">
                                <select
                                    className="form-input country-code-dropdown"
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                >
                                    {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {country.name} ({country.code})
                                        </option>
                                    ))}
                                </select>

                                <input
                                    type="tel"
                                    name="phone"
                                    className="form-input"
                                    placeholder="Phone"
                                    value={userDetails.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    className="form-input"
                                    placeholder="Email"
                                    value={userDetails.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    name="country"
                                    className="form-input"
                                    placeholder="Country"
                                    value={userDetails.country}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="state"
                                    className="form-input"
                                    placeholder="State"
                                    value={userDetails.state}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="postalCode"
                                    className="form-input"
                                    placeholder="Postal Code"
                                    value={userDetails.postalCode}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    name="address"
                                    className="form-input"
                                    placeholder="Address"
                                    value={userDetails.address}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    name="tradingViewId"
                                    className="form-input"
                                    placeholder="TradingView ID"
                                    value={userDetails.tradingViewId}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <h3>Select a Plan</h3>
                            <div className="plan-selection">
                                {plans.length > 0 && plans.map((plan) => (
                                    <button
                                        key={plan.planId}
                                        className={`plan-btn ${selectedPlan?.planId === plan.planId ? 'active' : ''}`}
                                        type="button"
                                        onClick={() => setSelectedPlan(plan)}
                                    >
                                        {plan.name} - ${plan.price}
                                    </button>
                                ))}
                            </div>

                            <div className="form-group terms-conditions">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    checked={termsAccepted}
                                    onChange={() => setTermsAccepted(!termsAccepted)}
                                />
                                <label htmlFor="terms">
                                    I accept the <a href="/terms" target="_blank">Terms and Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.
                                </label>
                            </div>

                            <StripeCheckout
                                userDetails={{ ...userDetails, phone: countryCode + userDetails.phone }}
                                selectedPlan={selectedPlan}
                                setPaymentSuccess={setPaymentSuccess}
                            />
                        </div>
                    </form>



                    {!paymentSuccess && selectedPlan && (
                        <div className="order-summary">
                            <h3>Total Amount</h3>
                            <h2>${selectedPlan.price}</h2>
                            <p>100% Secure Payment</p>

                            <div className="order-details">
                                <p><strong>Plan:</strong> {selectedPlan.name}</p>
                                <p><strong>Description:</strong> {selectedPlan.description}</p>
                                <p><strong>Total:</strong> ${selectedPlan.price}</p>
                            </div>
                        </div>
                    )}

                </div>

                <footer className="footer">
                    <p>
                        &copy; {new Date().getFullYear()} AlgoMaxx FZE. All rights reserved.
                        <br />
                        <a href="/terms" target="_blank">Terms and Conditions</a> | <a href="/privacy" target="_blank">Privacy Policy</a>
                    </p>
                </footer>
            </div>
        </Elements>
    );
};

// Component for Stripe Checkout Form
const StripeCheckout = ({ userDetails, selectedPlan, setPaymentSuccess }) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            toast.error('Stripe has not loaded yet. Please try again.');
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: userDetails.name,
                email: userDetails.email
            }
        });

        if (error) {
            toast.error(`Payment method error: ${error.message}`);
            return;
        }

        try {
            toast.info('Creating payment intent...');

            const paymentIntentResponse = await axios.post(`${API_URL}/stripe/create-payment-intent`, {
                amount: selectedPlan.price * 100,
                paymentMethodId: paymentMethod.id,
                userDetails,
                plan: selectedPlan,
            });

            const { clientSecret, status } = paymentIntentResponse.data;

            if (status === 'succeeded') {
                toast.success('Payment has already succeeded, skipping confirmation.');
                return;
            }

            toast.info('Confirming payment...');

            const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
            });

            if (confirmError) {
                toast.error(`Error confirming the payment: ${confirmError.message}`);
                return;
            }

            toast.success('Payment successful!');
            setPaymentSuccess(true);

            const response = await axios.post(`${API_URL}/grant-tradingview-access`, {
                userDetails,
                plan: selectedPlan,
                paymentIntentId: paymentIntent.id,
            });

            toast.success(response.data.message);
            navigate('/success');
        } catch (err) {
            toast.error('Backend error occurred.');
        }
    };

    return (
        <div className="payment-section">
            <h3>Payment Information</h3>
            <CardElement className="card-element" />
            <button type="submit" className="form-submit-btn" onClick={handlePaymentSubmit} disabled={!stripe}>
                Pay ${selectedPlan?.price}
            </button>
        </div>
    );
};

export default App;
